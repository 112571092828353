
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import DeliveryFilter from "@/modules/delivery/components/DeliveryFilter.vue";
import {
  clientConceptDeliveriesIndex,
  conceptDeliveriesIndex,
  DeliveryIndexItem,
} from "@/modules/delivery/api/conceptDeliveriesIndex";
import { conceptDeliveryProductDestroy } from "@/modules/delivery/api/conceptDeliveryProductDestroy";
import BulkDeleteButton from "@/modules/crudTable/components/BulkDeleteButton.vue";
import OrderStatusChip from "@/modules/salesOrder/components/OrderStatusChip.vue";
import { sendBlobToBrowserDownload } from "@/application/util/downloadFile";
import { exportClientDelivery } from "@/modules/delivery/api/exportImport";
import DeliveryImportDialog from "@/modules/delivery/components/DeliveryImportDialog.vue";
import { UserLevel } from "@/modules/product/enum/UserLevel";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<DeliveryIndexItem> & { clientId: number; id: number }[];
  isImportDialogOpen: boolean;
  filterComponent: VueConstructor;
  defaultFilters: Record<string, unknown>;
}

export default Vue.extend({
  name: "productTable",
  components: {
    DeliveryImportDialog,
    OrderStatusChip,
    BulkDeleteButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "clientName", sortable: false },
      { value: "reference", sortable: false },
      {
        value: "plannedDate",
        type: "date",
        typeParameters: { dateType: "DD MMM YYYY HH:mm" },
        sortable: false,
      },
      { value: "articleNumber", sortable: false },
      { value: "articleName", sortable: false },
      { value: "quantity", sortable: false },
      { value: "unit", sortable: false },
      { value: "statusId", sortable: false },
    ],
    selected: [],
    isImportDialogOpen: false,
    filterComponent: DeliveryFilter,
    defaultFilters: {
      dateFrom: undefined,
      dateTo: undefined,
    },
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient", "profile"]),
    computedHeaders(): CrudTableHeader[] {
      const headers = [...this.headers];

      if ([UserLevel.Default, UserLevel.Exit].includes(this.profile.level)) {
        return headers.filter((header) => header.value !== "clientName");
      }

      return headers;
    },
  },
  watch: {
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  methods: {
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set";
      this.selected = ids.map((id) => ({ id, clientId: this.client?.id }));
    },
    async deliveryIndex(data: PaginatedRequest) {
      if (!this.client) {
        return conceptDeliveriesIndex(data);
      }

      return clientConceptDeliveriesIndex(data, this.client.id);
    },
    async deliveryDestroy(item: DeliveryIndexItem) {
      await conceptDeliveryProductDestroy({
        clientId: item.clientId,
        deliveryId: item.id,
        detInternalIndex: item.detInternalIndex,
      });
      this.selected = this.selected.filter(
        (selected) => selected.id !== item.id
      );
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    deselectItems(ids: number[]) {
      this.selected = this.selected.filter((item) => !ids.includes(item.id));
    },
    handleEdit(item: DeliveryIndexItem) {
      this.$router.push({
        name: "delivery.edit",
        params: {
          deliveryId: item.id.toString(),
          clientId: item.clientId.toString(),
        },
      });
    },
    async downloadExport() {
      const response = await exportClientDelivery(this.client.id);
      sendBlobToBrowserDownload("deliveries", "xlsx", response.data);
    },
  },
});
